<template>
  <span class="invalid-feedback">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "FormTextError"
}
</script>

<style scoped>

</style>
